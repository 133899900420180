/* eslint-disable */

import orderBy from 'lodash/orderBy';
import {Link as RouterLink, useParams, useNavigate} from 'react-router-dom';
import React, { useEffect, useCallback, useState } from 'react';

import { Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack';// hooks
import {styled, useTheme} from "@mui/material/styles";



import useSettings from '../../hooks/useSettings';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// utils
import axios from '../../utils/axios';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';
import { SkeletonPostItem } from '../../components/skeleton';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import FileDisplay from "../../components/jdocs/FileDisplay";
import SubHeader from "../../components/jdocs/SubHeader";
import SampleFile from "../../assets/samples/Sample_PDF_File.pdf";
import DocPage from '../../components/Page';
import SinglePage from "../../components/jdocs/SinglePagePDFViewer";
import InfoDrawer from "../../components/jdocs/InfoDrawer";
import TagPicker from "../../components/jdocs/TagPicker";
import {API_URL} from "../../constants";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


export default function File() {
    const navigate = useNavigate();

    const { themeStretch } = useSettings();
    const options = {
        cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
        cMapPacked: true,
        standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,

    };
    const isMountedRef = useIsMountedRef();
    const [selectedTags, setSelectedTags] = useState([]);

    const [tags, setTags] = useState([]);
    const [file, setFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const { id } = useParams();

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    useEffect(async () => {
        console.log(id);
        const url = `${API_URL}/getFile`;
        try {
            // 77c5ca67-1cd7-44c7-b96a-bb944a5b2a57
            axios.post(url, {
                    "fileId":id
                },
                {
                    'Authorization': localStorage.getItem('authToken'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }).then(response => {
                return response;
            }).then(data => {
                console.log(data);
                if (data.data.errorId != null){
                    navigate("/404");
                }
                setFile(data);
            }).then({

            }).catch((ex) => {
                console.log(`Error Encountered ${ex}`);
                // TODO: Add a 404 file direction. For now all errors go to 404
                navigate("/404");
            });

        } catch (error) {
            console.error(error);
        }

        const url2 = `${API_URL}/getTags`;
        try {
            // 77c5ca67-1cd7-44c7-b96a-bb944a5b2a57
            axios.post(url2, {
                    "fileId":id
                },
                {
                    'Authorization': localStorage.getItem('authToken'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }).then(response => {
                return response;
            }).then(data => {
                console.log(data);
                const listOfTags = [];
                for (let i = 0; i < data.data.tags.length; i += 1) {
                    console.log(data.data.tags[i]);
                    listOfTags[i] = {
                        "id": data.data.tags[i].id,
                        "name": data.data.tags[i].name,
                        "color": data.data.tags[i].colorHex
                    }

                }
                console.log(listOfTags);

                setTags(listOfTags);
            }).then({

            }).catch((ex) => {
                console.log(`Error Encountered ${ex}`);
                // TODO: Add a 404 file direction. For now all errors go to 404
                navigate("/404");
            });

        } catch (error) {
            console.error(error);
        }

        const url3 = `${API_URL}/getTagsSelected`;
        try {
            // 77c5ca67-1cd7-44c7-b96a-bb944a5b2a57
            axios.post(url3, {
                    "fileId":id
                },
                {
                    'Authorization': localStorage.getItem('authToken'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }).then(response => {
                return response;
            }).then(data => {
                console.log(data);
                const listOfTags = [];
                for (let i = 0; i < data.data.tags.length; i += 1) {
                    console.log(data.data.tags[i]);
                    listOfTags[i] = {
                        "id": data.data.tags[i].id,
                        "name": data.data.tags[i].name,
                        "color": data.data.tags[i].colorHex
                    }

                }
                console.log(listOfTags);

                setSelectedTags(listOfTags);
            }).then({

            }).catch((ex) => {
                console.log(`Error Encountered ${ex}`);
                // TODO: Add a 404 file direction. For now all errors go to 404
                navigate("/404");
            });

        } catch (error) {
            console.error(error);
        }
    }, []);

const onClick = (e) => {
    navigate('/app/upload');
    console.log("Test");
}
    return (

        <DocPage title='JDocs'>
            <SubHeader name={'File'} action={onClick}/>

            {/* eslint-disable-next-line react/jsx-no-bind */}
            {file &&<TagPicker selected={selectedTags} labels={tags} fileId={id}/>}
            {file &&<div> <FileDisplay name={file.data.fileName} navigate={navigate} fileId={id}/></div>}
            {file &&<div> <SinglePage pdf={file.data.url} /></div>}



        </DocPage>
    );
}
