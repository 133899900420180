import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ViewComfySharpIcon from '@mui/icons-material/ViewComfySharp';
import ViewListIcon from '@mui/icons-material/ViewList';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import InfoDrawer from "./InfoDrawer";


export default function ToolTipIcon(props) {
  return (
      props.title === 'Info' && <InfoDrawer title={props.title} /> ||
        <Tooltip title={props.title}>
          <IconButton
              onMouseDown={(event) => event.stopPropagation()}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                props.passedCommand(props.fileId, props.navigate);
              }}
          >
            {(props.title === 'Delete' && <DeleteOutlinedIcon/>)
            || (props.title === 'Options' && <MoreVertOutlinedIcon/>)
            || (props.title === 'Filter' && <FilterAltIcon/>)
            || (props.title === 'Grid View' && <ViewComfySharpIcon/>)
            || (props.title === 'List View' && <ViewListIcon/>)
            || (props.title === 'Download' && <FileDownloadOutlinedIcon/>)
            || (props.title === 'Close' && <CloseRoundedIcon/>)

            }
          </IconButton>
        </Tooltip>

  );
}
