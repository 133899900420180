/* eslint-disable */

import * as React from 'react';
import {useParams, useNavigate} from "react-router-dom";

import Typography from '@mui/material/Typography';
import ToolTipIcon from './ToolTipIcon';
import axios from "axios";
import {API_URL} from "../../constants";



function deleteThisFile(fileId, navigate) {
        //const { id } = "test";
        //const navigate = useNavigate();

        if (window.confirm('Are you sure you wish to delete this item?')){
            console.log('Delete this file?');
            const formData = new FormData();
            formData.append('fileId', fileId);
            axios
                .post((`${API_URL}/deleteFile`), {'fileId':fileId,
                    'projectId':sessionStorage.getItem("caseId") },{
                    headers: {
                        'Authorization': localStorage.getItem('authToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then((res) => {
                    console.log(res);
                    navigate('/app/cases');
                })
                .catch((err) => {
                    console.log(err);
                });

        } else {
            console.log('Do not delete this file');
        }
}

export default function  FileDisplay(props) {

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4">{props.name}</Typography>
                <div style={{ display: 'flex', flexDirection: 'row', flexBasis: 'content', margin: '0' }}>
                    <ToolTipIcon title={'Download'} passedCommand={null} />
                    <ToolTipIcon title={'Delete'} passedCommand={deleteThisFile} fileId={props.fileId} navigate={props.navigate}/>

                    <ToolTipIcon title={'Info'}  />

                    <ToolTipIcon title={'Options'} passedCommand={null} />
                </div>
            </div>
        </div>


        // <Letter html={props.html} text={props.text} />
    );
}
