/* eslint-disable */
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import {Chip} from "@mui/material";
import {useEffect} from "react";
import axios from "../../utils/axios";
import {API_URL} from "../../constants";

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${
                theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
            }`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
                {
                    backgroundColor: theme.palette.action.hover,
                },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${
        theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
    }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${
                theme.palette.mode === 'light'
                    ? 'rgba(3, 102, 214, 0.3)'
                    : 'rgb(12, 45, 107)'
            }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
        },
    },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
    fontWeight: 600,
    '&:hover,&:focus': {
        color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
    },
    '& span': {
        width: '100%',
    },
    '& svg': {
        width: 16,
        height: 16,
    },
}));

export default function TagPicker(props) {
    const labels = props.labels;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState([]);
    const [pendingValue, setPendingValue] = React.useState([]);
    const theme = useTheme();

    React.useEffect(() => {
        setValue(props.selected);
    }, [props.selected])
    const handleClick = (event) => {
        console.log(props.labels);
        console.log(props.selected);
        console.log(value);

        setPendingValue(value);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setValue(pendingValue);
        console.log(pendingValue);
            for (let i = 0; i < pendingValue.length; i++) {
                console.log(pendingValue[i]);
                const url = `${API_URL}/addTag`;
                try {
                    // 77c5ca67-1cd7-44c7-b96a-bb944a5b2a57
                    axios.post(url, {
                            "tagId":pendingValue[i].id,
                            "documentId":props.fileId
                        },
                        {
                            'Authorization': localStorage.getItem('authToken'),
                            'Accept': 'application/json',
                            'Contfent-Type': 'application/json'
                        }).then(response => {
                        return response;
                    }).then(data => {
                        console.log(data);
                        if (data.data.errorId != null){
                            navigate("/404");
                        }
                    }).then({

                    }).catch((ex) => {
                        console.log(`Error Encountered ${ex}`);
                        // TODO: Add a 404 file direction. For now all errors go to 404
                    });

                } catch (error) {
                    console.error(error);
                }
            }
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    return (
        <div>
            <Box sx={{ width: 221, fontSize: 13 }}>
                <Button disableRipple aria-describedby={id} onClick={handleClick}>
                    <span>Tags</span>
                    <SettingsIcon />
                </Button>
                {value.map((label) => (
                    <Chip
                        key={label.name}
                        label={label.name}
                        style={{
                            backgroundColor: label.color,
                            color: theme.palette.getContrastText(label.color),
                        }}
                    >
                        {label.name}
                    </Chip>
                ))}
            </Box>
            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Autocomplete
                            open
                            multiple
                            onClose={(event, reason) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            value={pendingValue}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    event.key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }
                                setPendingValue(newValue);
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="No labels"
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Box
                                        component={DoneIcon}
                                        sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden',
                                        }}
                                    />
                                    <Box
                                        component="span"
                                        sx={{
                                            width: 14,
                                            height: 14,
                                            flexShrink: 0,
                                            borderRadius: '3px',
                                            mr: 1,
                                            mt: '2px',
                                        }}
                                        style={{ backgroundColor: option.color }}
                                    />
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            '& span': {
                                                color:
                                                    theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                                            },
                                        }}
                                    >
                                        {option.name}
                                        <br />
                                        <span>{option.description}</span>
                                    </Box>
                                    <Box
                                        component={CloseIcon}
                                        sx={{ opacity: 0.6, width: 18, height: 18 }}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden',
                                        }}
                                    />
                                </li>
                            )}
                            options={[...labels].sort((a, b) => {
                                // Display the selected labels first.
                                let ai = value.indexOf(a);
                                ai = ai === -1 ? value.length + labels.indexOf(a) : ai;
                                let bi = value.indexOf(b);
                                bi = bi === -1 ? value.length + labels.indexOf(b) : bi;
                                return ai - bi;
                            })}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <StyledInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder="Filter labels"
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </div>
    );
}
// From https://github.com/abdonrd/github-labels
const label2s = [
    {
        name: 'Privileged',
        color: '#7057ff',
        description: 'File should not be distributed',
    },
    {
        name: 'Not Privileged',
        color: '#008672',
        description: 'No extra attention is needed',
    },
    {
        name: 'Rochester Builders',
        color: '#b60205',
        description: '',
    },
    {
        name: 'LGX Janitorial',
        color: '#d93f0b',
        description: '',
    },
    {
        name: 'PGP Security',
        color: '#0e8a16',
        description: '',
    },
    {
        name: 'Jen Marlow',
        color: '#fbca04',
        description: '',
    },
    {
        name: "Mike Marlow",
        color: '#fec1c1',
        description: '',
    },
    {
        name: 'Production 3',
        color: '#215cea',
        description: '',
    },
    {
        name: 'Production 2',
        color: '#cfd3d7',
        description: 'Created on 7/24/2022',
    },
    {
        name: 'Production 1',
        color: '#fef2c0',
        description: '',
    },
    {
        name: 'Review',
        color: '#eeeeee',
        description: 'Needs further review',
    },
    {
        name: 'New',
        color: '#d73a4a',
        description: "Added this week",
    },
];
