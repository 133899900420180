import React from "react";
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import DashboardRoundedIcon from '@mui/icons-material/DashboardOutlined';
import QueueIcon from '@mui/icons-material/QueueOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAltOutlined';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/Inventory2Outlined';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import File from "../../../pages/jdocs/File";



// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: <DashboardRoundedIcon/>,
  cases: <CasesOutlinedIcon/>,
  queues: <QueueIcon/>,
  members: <PeopleAltIcon/>,
  fileUpload: <FileUploadIcon/>,
  production: <ArchiveOutlinedIcon/>,
  settings: <SettingsIcon/>,
  blog: getIcon('ic_blog'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  analytics: getIcon('ic_analytics'),

  help: getIcon('ic_analytics'),
  gear: getIcon('ic_analytics'),

  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.jdocs.home, icon: ICONS.dashboard },
      { title: 'Cases', path: PATH_DASHBOARD.jdocs.cases, icon: ICONS.cases },
      { title: 'Queues', path: PATH_DASHBOARD.jdocs.queues, icon: ICONS.queues },
      { title: 'Members', path: PATH_DASHBOARD.jdocs.members, icon: ICONS.members },
      { title: 'File Upload', path: PATH_DASHBOARD.jdocs.fileUpload, icon: ICONS.fileUpload },
      { title: 'Production', path: PATH_DASHBOARD.jdocs.production, icon: ICONS.production },
      { title: 'Settings', path: PATH_DASHBOARD.jdocs.settings, icon: ICONS.settings },
    ],
  }
];

export default navConfig;
