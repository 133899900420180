import PropTypes from 'prop-types';
import * as React from 'react';
import { DesktopDatePicker } from '@mui/lab';

// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  InputAdornment,
  Link,
  Typography,
  Autocomplete,
  Popper,
  TextField,
  Button,
  Popover,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// hooks
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import SearchBar from './SearchBar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import LongLogo from '../../../components/LongLogo';
import CaseSelectPopover from './CaseSelectPopover';
import SearchNotFound from '../../../components/SearchNotFound';
import InputStyle from '../../../components/InputStyle';
import Image from '../../../components/Image';
import SearchChipArray from '../../../components/jdocs/SearchChipArray';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `100%`,
      alignItems: 'flex-end',
      boxShadow : '0px 0px 4px rgba(0, 0, 0, 0.25)',
      '& .MuiToolbar-root':{
          width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH - 20}px)`,
      },

      // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      // width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------
const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
  // width: '280px !important',
});
DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const [anchorEl, setAnchorEl] = React.useState(); // eslint-disable-line no-bitwise, no-self-compare
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();
  const options = ['test1'];

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (

        <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
          <Toolbar
              sx={{
                minHeight: '100% !important',
                px: { lg: 5 },
                justifyContent: 'space-between',

              }}
          >
            {!isDesktop && (
                <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                  <Iconify icon="eva:menu-2-fill" />
                </IconButtonAnimate>
            )}

              <div style={{display: 'flex', justifyContent: 'flex-start', flexGrow: '1', gap: '60px', alignItems: 'center'}}>
                  {isDesktop && <LongLogo />}

                  <Autocomplete
                      size="large"
                      freeSolo
                      autoHighlight
                      disableCloseOnSelect="true"
                      sx={{
                          flexGrow: '1',
                          maxWidth: '850px',
                          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.palette.common.white,
                          },
                      }}
                      PopperComponent={PopperStyle}
                      getOptionLabel={(post) => post.title}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                          <InputStyle
                              {...params}
                              sx={{backgroundColor: theme.palette.grey[200], borderRadius: '8px'}}
                              placeholder="Search documents, cases, etc..."
                              InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          <SearchIcon sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                                      </InputAdornment>
                                  ),
                                  endAdornment: (
                                      <InputAdornment position="end" onClick={handleOpen}>
                                          <ArrowDownwardIcon sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                                      </InputAdornment>
                                  ),
                              }}
                          />
                      )}
                      renderOption={(props, { inputValue }) => {
                          return (
                              <Container sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <Box>
                                      Contains
                                      <TextField fullWidth id="standard-basic" label="Case Name" variant="standard" />
                                  </Box>
                                  <Box>
                                      <b>And</b> File Date
                                      <DesktopDatePicker
                                          label="File Created Date"
                                          inputFormat="MM/dd/yyyy"
                                          renderInput={(params) => <TextField {...params} />}
                                      />
                                  </Box>
                                  <Box>
                                      <b>And</b> Date Added
                                      <DesktopDatePicker
                                          label="File Added Date"
                                          inputFormat="MM/dd/yyyy"
                                          renderInput={(params) => <TextField {...params} />}
                                      />
                                  </Box>

                                  <Box>
                                      <b>And</b> File Type
                                      <FormControl fullWidth>
                                          <InputLabel id="demo-simple-select-label">File Type</InputLabel>
                                          <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Age">
                                              <MenuItem value={1}>PDF</MenuItem>
                                              <MenuItem value={2}>Word</MenuItem>
                                              <MenuItem value={3}>Excel</MenuItem>
                                          </Select>
                                      </FormControl>
                                  </Box>
                                  <Box>
                                      <b>And</b> Tags
                                      <SearchChipArray />
                                  </Box>
                              </Container>
                          );
                      }}
                      options={options}
                  />
              </div>


            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
            >
              <Container sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
                <Box>
                  Contains
                  <TextField id="standard-basic" label="Case Name" variant="standard" />
                </Box>
                <Box>
                  <b>And</b> File Date
                  <DesktopDatePicker
                      label="File Created Date"
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
                <Box>
                  <b>And</b> Date Added
                  <DesktopDatePicker
                      label="File Added Date"
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => <TextField {...params} />}
                  />
                </Box>

                <Box>
                  <b>And</b> File Type
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">File Type</InputLabel>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Age">
                      <MenuItem value={1}>PDF</MenuItem>
                      <MenuItem value={2}>Word</MenuItem>
                      <MenuItem value={3}>Excel</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <b>And</b> Tags
                  <SearchChipArray />
                </Box>
              </Container>
            </Popover>

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <CaseSelectPopover sx={{ flexBasis: 'auto' }} />
              <NotificationsPopover />
              <AccountPopover />
            </Stack>
          </Toolbar>
        </RootStyle>

  );
}
