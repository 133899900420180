
export default function ToolBar(theme) {
    return {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    padding: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    [theme.breakpoints.up('lg')]: {
                        paddingLeft: '10px !important',
                        paddingRight: '16px !important',
                    },
                    [theme.breakpoints.up('sm')]: {
                        paddingLeft: '16px',
                        paddingRight: '16px',
                    },
                },
            },
        },
    };
}