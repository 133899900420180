import React from "react";
import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
// components
import { IconButtonAnimate } from '../../../components/animate';



// ----------------------------------------------------------------------

CollapseButton.propTypes = {
  collapseClick: PropTypes.bool,
  onToggleCollapse: PropTypes.func,
};

export default function CollapseButton({ onToggleCollapse, collapseClick }) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(collapseClick && {
            transform: 'none',
          }),
            zIndex: '2100',
        }}
      >
          <MenuRoundedIcon/>
      </Box>
    </IconButtonAnimate>
  );
}
