/* eslint-disable */
import * as React from "react";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {Route} from "react-router";
import {useNavigate} from "react-router-dom";

export default function SubHeaderActionButton(props){
    const navigate = useNavigate();

    const BUTTON_NAMES = {
        'File Uploads': 'New Upload',
        'Members': 'Add New Member',
        'Queues': 'New Queue',
        'Cases': 'Add New Case',
        'Production': 'Export',
        'Files': 'Save to Queue',
        'File': 'Save to Queue',
        'Case': 'Upload Files',
    };

    const BUTTON_ICONS = {
        'File Uploads': <AddIcon/>,
        'Members': <AddIcon/>,
        'Queues': <AddIcon/>,
        'Cases': <AddIcon/>,
        'Production': <AddIcon/>,
        'Case': <AddIcon/>,

    };

    const onClick = () => {
        navigate("/app/fileUpload")
    };


    return (
            <Button variant="contained" size="medium" sx={{gap: '3px'}} onClick={onClick}>
                <Typography variant="subtitle1" sx={{minWidth: 'max-content', lineHeight: '1.5'}}>
                    {BUTTON_NAMES[props.headerName]}
                </Typography>
                {BUTTON_ICONS[props.headerName]}
            </Button>
        )
}