/* eslint-disable */

import React, {useEffect, useState} from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, Typography, ListItemText, ListItemAvatar, MenuItem, Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';


// utils
import { green, grey } from '@mui/material/colors';
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _contacts } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import BadgeStatus from '../../../components/BadgeStatus';
import { IconButtonAnimate } from '../../../components/animate';
import CaseCard from '../../../components/jdocs/CaseCard';
import {API_URL} from "../../../constants";
import {useNavigate} from "react-router-dom";

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export default function CaseSelectPopover() {
  const [open, setOpen] = useState(false);
  const [cases, setCases] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const url = `${API_URL}/getCases`;

        fetch(url, {
            headers: {
                'Authorization': localStorage.getItem('authToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(data => {

            setCases(data.projects);
        }).then({

        }).catch(() => {
            console.log("Error Encountered");
        });

        const tempCases = [
            {
                UUID: '1',
                caseName: 'John vs. Apple County'
            },
            {
                UUID: '2',
                caseName: 'John vs. Apple County'
            },
            {
                UUID: '3',
                caseName: 'John vs. Apple County'
            },
        ];
    }, []);
  const handleOpen = (event) => {
    const url = `${API_URL}/getCases`;
    fetch(url, {
      headers: {
        Authorization: localStorage.getItem('authToken'),
        Accept: 'application/json',
        'Contfent-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCases(data.projects);
      })
      .then({})
      .catch(() => {
        console.log('Error Encountered');
      });
    setOpen(event.currentTarget);
  };

  const caseCardList = cases.map((caseItem, i) => {
    return (
        <ListItem key={caseItem.id}>
            <ListItemButton >
                <ListItemIcon>
                    <WorkOutlineOutlinedIcon/>
                </ListItemIcon>
                <Typography>{caseItem.name}</Typography>
            </ListItemButton>
        </ListItem>

    );
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        style={{
          borderColor: '#E5E5E5',
          border: '2px solid',
          borderRadius: '24px',
          marginLeft: '1%',
          minWidth: '158px',
          gap: '5px',
        }}
      >
        <Iconify icon={'akar-icons:circle-fill'} sx={{ color: '#1D9509' }} width={10} height={10} />
        Select Case
        <Iconify icon={'akar-icons:arrow-down'} width={20} height={20} />
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <List>{cases ? caseCardList : null}</List>
      </MenuPopover>
    </div>
  );
}
