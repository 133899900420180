import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import useTheme from '@mui/material/styles/useTheme';
import CardActionArea from '@mui/material/CardActionArea';
import Container from "@mui/material/Container";

import ToolTipIcon from './ToolTipIcon';

export default function CaseCard(props) {
    const theme = useTheme();

    const updateCurrentCase = () => {
        localStorage.setItem("currentCase", props.caseId);
    }

    return (
        <Card
        >
            <CardActionArea onClick={updateCurrentCase} href={`/app/case/${props.caseId}`} sx={{ width: '250px', backgroundColor: '#e6e6e6',height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'stretch' }}>
                <CardContent
                    sx={{
                        padding: theme.spacing(1),
                        '&:last-child': {
                            padding: theme.spacing(1),
                        },
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px', padding: '5px' }}>
                            <WorkOutlineOutlinedIcon />
                            <Typography variant="h5" component="div">
                                {props.caseName}
                            </Typography>
                        </div>
                        <ToolTipIcon title={'Options'}/>
                    </div>
                    <div style={{paddingLeft: '40px',paddingBottom: '20px'}}><i>{props.fileCount} files</i>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}