
import * as React from "react";
import {Button} from "@mui/material";
import Chip from "@mui/material/Chip";
import ToolBar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography";
import ToolTipIcon from "./ToolTipIcon";
import SubHeaderActionButton from "./SubHeaderActionButton";



export default function SubHeader(props) {

    return (
        <div style={{display: "flex", justifyContent: 'space-between', margin: '1em 0'}}>
            <div>
                <Typography variant="h3">
                    {props.trueName ? props.trueName : props.name}
                </Typography>
            </div>
            <div style={{display: "flex", justifyContent: "packed", alignItems: 'center', gap: "10px"}}>
                <div style={{display: "flex", }}>
                    <ToolTipIcon title={'List View'} />
                    <ToolTipIcon title={'Filter'} />
                </div>

                <SubHeaderActionButton headerName={props.name} buttonAction={props.action}/>
            </div>

        </div>
    )
}